import React from "react"
import * as styles from "./footer.module.scss"
import { Link } from "gatsby"
import Credits from "./Credits"
import FooterLocation from "./FooterLocation"

import { AppContext } from "../context/context"
const LandingFooter = ({
  companyName,
  logo,
  location,
  trackingPhone,
  footerNavItems,
  customCTA,
  additionalOffices,
}) => {
  const { size } = React.useContext(AppContext)
  const [office] = location.value
  const hasLogo = logo.value.length > 0 ? true : false
  if (size < 1024) {
    return (
      <footer className={styles.footer}>
        <div className="container">
          <div className={styles.footerInner}>
            {hasLogo && (
              <Link to="/" className={styles.logo}>
                <img
                  src={logo.value[0].fluid.src}
                  alt={logo.value[0].description}
                />
              </Link>
            )}
            <div className={styles.footerRight}>
              {customCTA ? (
                <p>{customCTA}</p>
              ) : (
                <p>Request your Free Inspection</p>
              )}
              <a
                href={`tel:${trackingPhone}`}
                aria-label="call button"
                className={styles.phone}
              >
                {trackingPhone}
              </a>
            </div>

            <div className={styles.locationWrapper}>
              <FooterLocation
                location={office.elements}
                companyName={companyName}
              />
              {additionalOffices.value.map(({ elements, id }) => {
                return <FooterLocation location={elements} key={id} />
              })}
            </div>
          </div>
        </div>
        <Credits footerNavItems={footerNavItems} companyName={companyName} />
      </footer>
    )
  } else {
    return (
      <footer className={styles.footer}>
        <div className="container">
          <div className={styles.footerInner}>
            <div className={styles.footerLeft}>
              {hasLogo && (
                <Link to="/" className={styles.logo}>
                  <img
                    src={logo.value[0].fluid.src}
                    alt={logo.value[0].description}
                  />
                </Link>
              )}
              {size >= 1024 && additionalOffices.value.length <= 2 && (
                <div
                  className={styles.locationWrapper}
                  style={{
                    gridTemplateColumns: `repeat(${
                      additionalOffices.value.length + 1
                    }, 1fr)`,
                  }}
                >
                  <FooterLocation
                    location={office.elements}
                    companyName={companyName}
                    hasLogo={hasLogo}
                  />
                  {additionalOffices.value.map(({ elements, id }) => {
                    return <FooterLocation location={elements} key={id} />
                  })}
                </div>
              )}
            </div>

            <div className={styles.footerRight}>
              {customCTA ? (
                <p>{customCTA}</p>
              ) : (
                <p>Request your Free Inspection</p>
              )}
              <a
                href={`tel:${trackingPhone}`}
                aria-label="call button"
                className={styles.phone}
              >
                {trackingPhone}
              </a>
            </div>
          </div>
        </div>
        <Credits footerNavItems={footerNavItems} companyName={companyName} />
      </footer>
    )
  }
}

LandingFooter.defaultProps = {
  social: {},
  logo: [],
  location: [],
  trackingPhone: "(207) 888-8888",
}

export default LandingFooter
